
const Pagination = ({currentPage, totalProperties, perPage, onPaginationChange, status}) => {
    if (status !== 'success') return null;

    let prevBtn = null;
    let nextBtn = null;
    let maxPageProperties = 0;
    let minPageProperties = 0;

    if (totalProperties > perPage) {
        maxPageProperties = currentPage * perPage;
        minPageProperties = (maxPageProperties - perPage) + 1;

        const maxPage = Math.ceil(totalProperties / perPage);

        prevBtn = (<li className="d-print-none page-item">
                    <button className="page-link" onClick={() => onPaginationChange(+currentPage-1)}>Anterior</button>
                </li>);
        if (currentPage < 2) {
            prevBtn = (<li className="d-print-none page-item disabled">
                            <span className="page-link">Anterior</span>
                        </li>);
        }

        nextBtn = (<li className="d-print-none page-item">
                    <button className="page-link" onClick={() => onPaginationChange(+currentPage+1)}>Siguiente</button>
                </li>);
        if (currentPage >= maxPage) {
            maxPageProperties = totalProperties;

            nextBtn = (<li className="d-print-none page-item disabled">
                            <span className="page-link">Siguiente</span>
                        </li>);
        }
    } else {
        maxPageProperties = totalProperties;
        minPageProperties = 1;
    }




    return (
        <nav aria-label="Properties navigation">
            <ul className="pagination justify-content-md-end align-items-stretch">
                {prevBtn}
                <li className="page-item bg-light"><span className="page-link px-5">{minPageProperties} a {maxPageProperties} de {totalProperties} propiedades totales</span></li>
                {nextBtn}
            </ul>
        </nav>
    );
};

export default Pagination;