const requestHeaders = new Headers();
requestHeaders.append("X-AUTH-APIKEY", `9a9f39682b05f540796d50b6c179d4a72cf2a47f`);

const requestOptions = {
    method: 'GET',
    headers: requestHeaders,
    redirect: 'follow'
};

const apiBaseURl = 'https://api-frontend.inmokey.com';

// Get Cities
export const getCities = async () => {
    try {
        const fetchedCities = await fetch(`${apiBaseURl}/settings/cities`, requestOptions);
        return await fetchedCities.json();
    }
    catch(error) {
        console.error(error);
        return Promise.reject()
    }

}

// Get property types
export const getPropertyTypes = async () => {
    try {
        const fetchedPropertyTypes = await fetch(`${apiBaseURl}/settings/property_types`, requestOptions);
        return await fetchedPropertyTypes.json();
    }
    catch(error) {
        console.error(error);
        return Promise.reject()
    }

}

// Get transaction types
export const getTransactionTypes = async () => {
    try {
        const fetchedTransactionTypes = await fetch(`${apiBaseURl}/settings/transaction_types`, requestOptions);
        return await fetchedTransactionTypes.json();
    }
    catch(error) {
        console.error(error);
        return Promise.reject()
    }

}

// Get filters
export const getFilters = async () => {
    return await Promise.all([getCities(), getPropertyTypes(), getTransactionTypes()])
}

// Get properties
export const getProperties = async (filters = null, page = 1, pp = 25) => {
    // Set filter params for request
    let baseParams = `?pageSize=${pp}&pageNumber=${page > 1 ? page : 1}`;

    if (filters && typeof filters === 'object') {
        for (let filter in filters) {
            let filterValue = null;

            if (filters[filter]) {
                switch (typeof filters[filter]) {
                    case "number":
                    case "string":
                        filterValue = filters[filter];
                        break;
                    case "object":
                        if (Array.isArray(filters[filter])) {
                            /* Filters may have the format [{value:"filterValue1", ...}, {value:"filterValue1", ...}, ...] */
                            const valuesArray = [];

                            filters[filter].forEach(item => {
                                if (item.hasOwnProperty('value')) {
                                    valuesArray.push(item.value);
                                }
                            });

                            filterValue = valuesArray.length > 0 ? JSON.stringify(valuesArray) : null;
                        } else if (filters[filter].hasOwnProperty('value')) {
                            /* Filters may have the format {value:"filterValue1", ...} */
                            filterValue = filters[filter].value;
                        }
                        break;
                    default:
                        filterValue = null;
                        break;
                }
            }
            if (filterValue) {
                baseParams += `&${filter}=${filterValue}`
            }
        }
    }

    // Send request
    try {
        const fetchProperties = await fetch(`${apiBaseURl}/properties${baseParams}`, requestOptions);
        return  await fetchProperties.json();
    }
    catch(error) {
        console.error(error);
        return Promise.reject()
    }
}