import { apiFields } from './apiFields';

export const createTableHeader = (enabledFields = {}) => {
    let headings = [];
    for (let field in enabledFields) {
        if (enabledFields[field]) {
            headings.push(<th scope="col" key={field}>{apiFields[field].label}</th>);
        }
    }

    return headings
}

export const createTableContent = (properties = [], contentStatus = 'loading', enabledFields, colSpan = null) => {
    // Error status
    if (contentStatus === 'error') {
        return (<tr className="table-danger">
                    <td colSpan={ colSpan }>
                        <p className="text-center">Error al cargar las propiedades</p>
                    </td>
                </tr>);
    }
    // Success status
    if (contentStatus === 'success') {
        if (properties.length < 1) {
            return (<tr className="table-info">
                        <td colSpan={ colSpan > 1 ? `${colSpan}` : null }>
                            <p className="text-center">No hay propiedades para mostrar</p>
                        </td>
                    </tr>);
        } else {
            const tableContent = [];
            properties.forEach((property, index) => {
                const rowContent = [];

                for (let field in enabledFields) {
                    if (enabledFields[field]) {
                        const fieldContentRef = apiFields[field].fields;
                        const fieldContentSeparator = apiFields[field].separator || '';
                        const fieldContentPrefix = apiFields[field].prefix ? apiFields[field].prefix+" " : '';
                        const fieldContentSuffix = apiFields[field].suffix ? " "+apiFields[field].suffix : '';

                        let fieldContent = '';

                        if (Array.isArray(fieldContentRef)) {
                            fieldContent = fieldContentRef.reduce((ongoingContent, currentRef) => {
                                const currentContent = extractContentFromItem(property, currentRef);

                                if (currentContent) {
                                    return ongoingContent ? ongoingContent+fieldContentSeparator+currentContent : currentContent;
                                }

                                return ongoingContent || '';
                            }, '');
                        } else {
                            fieldContent = extractContentFromItem(property, fieldContentRef);
                        }
                        if (fieldContent) {
                            fieldContent = `${fieldContentPrefix}${fieldContent}${fieldContentSuffix}`
                        } else {
                            fieldContent = '-'
                        }
                        rowContent.push(<td key={`${field}-00${index}`}>{fieldContent}</td>);
                    }
                }

                if (rowContent.length > 0) tableContent.push(<tr key={property.id}>{rowContent}</tr>)
            });

            return tableContent
        }
    }

    // Loading status
    return (<tr className="table-warning">
        <td colSpan={ colSpan }>
            <p className="text-center">Cargando...</p>
        </td>
    </tr>)
}

export const extractContentFromItem = (item, ref) => {
    const refArray = ref.split('.').filter(refItem => refItem.trim() !== '');

    if (refArray.length === 1) return item[refArray[0]] || '';

    // Deep dive into nested object keys
    return refArray.reduce((currentItem, currentRef) => {
        return currentItem[currentRef] || ''
    }, item)
}