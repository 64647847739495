import {useEffect, useState} from 'react';

import Filters from './Filters';
import FieldsSelect from './FieldsSelect';
import PropertiesTable from './PropertiesTable';


import {getFilters} from '../../api/requests';
// import { apiFields } from '../../api/apiFields';

let initialFields = {
    name: false,
    ref: true,
    prop_type: false,
    transaction: false,
    dir: true,
    neighborhood: false,
    location: false,
    rooms: false,
    bedrooms: false,
    bathrooms: false,
    garages: true,
    area: true,
    area_covered: true,
    area_ground: false,
    expenses: true,
    price: true
};
let initialFilters = {
    city: null,
    propertyType: null,
    transactionType: null
};

 // Get applied filters from localStorage
 if (localStorage.getItem('NMP_appliedFilters')) {
    const prevFilters = JSON.parse(localStorage.getItem('NMP_appliedFilters'));
    if (typeof prevFilters === 'object') initialFilters = prevFilters;
}
// Get used fields from localStorage
if (localStorage.getItem('NMP_appliedFields')) {
    const prevFields = JSON.parse(localStorage.getItem('NMP_appliedFields'));
    if (typeof prevFields === 'object') initialFields = prevFields;
}

const Main = () => {
    const [actionToShow, setActionToShow] = useState('filters');
    const [filtersStatus, setFiltersStatus] = useState('loading');
    const [filtersContent, setFiltersContent] = useState(
        {
            cities: null,
            properties_type: null,
            transactions_type: null
        }
        );
    const [propertiesFilters, setPropertiesFilters] = useState({
        city: null,
        propertyType: null,
        transactionType: null
    });
    const [enabledFields, setEnabledFields] = useState(initialFields);

    const [filtersToPrint, setFiltersToPrint] = useState([]);

    // Loading filter on first loading
    useEffect(() => {
        getFilters()
        .then(results => {
            setFiltersStatus('success');
            setFiltersContent({
                cities: results[0],
                properties_type: results[1],
                transactions_type: results[2]
            });
            setPropertiesFilters(initialFilters);
        })
        .catch(() => {
            setFiltersStatus('error');
            setFiltersContent({
                cities: null,
                properties_type: null,
                transactions_type: null
            });
        });
    }, []);

    useEffect(() => {
        let contentToPrint = [];
        if (propertiesFilters.city && propertiesFilters.city.length > 0) {
            contentToPrint.push(<p className="col-12 col-md mb-2" key="cityFilterText"><strong>Ciudad: </strong>{propertiesFilters.city.map(filter => filter.label).join(', ')}</p>);
        }
        if (propertiesFilters.propertyType && propertiesFilters.propertyType.length > 0) {
            contentToPrint.push(<p className="col-12 col-md mb-2" key="propertyFilterText"><strong>Propiedad: </strong>{propertiesFilters.propertyType.map(filter => filter.label).join(', ')}</p>);
        }
        if (propertiesFilters.transactionType) {
            contentToPrint.push(<p className="col-12 col-md mb-2" key="transactionFilterText"><strong>Transacción: </strong>{propertiesFilters.transactionType.label}</p>);
        }

        setFiltersToPrint(contentToPrint);
    }, [propertiesFilters]);

    const updateFilters = newFilters => {
        if (newFilters) {
            const [prevCity, prevProperty, prevTransaction] = Object.values(propertiesFilters);
            const [newCity, newProperty, newTransaction] = Object.values(newFilters);

            if (newCity !== prevCity || newProperty !== prevProperty || newTransaction !== prevTransaction) setPropertiesFilters(newFilters);
        }
    }

    let actionHeaderContent = <h5 className="card-title text-center">Contenido no disponible</h5>
    if (actionToShow === 'filters') {
        actionHeaderContent = <Filters filters={filtersContent} status={filtersStatus} onFiltersChange={updateFilters} settedFilters={propertiesFilters} />
    }
    if (actionToShow === 'fields') {
        actionHeaderContent = <FieldsSelect fields={enabledFields} onChangeFields={setEnabledFields} />
    }

    const navItems = [
        {label: 'filters', text: 'Filtros'},
        {label: 'fields', text: 'Columnas'}
    ];

    return (
        <main className="container-lg">
            <div className="filters_card card my-5">
                <div className="card-header d-print-none">
                    <ul className="nav nav-tabs card-header-tabs">
                        {
                            navItems.map(item => (
                                <li className="nav-item" key={item.label}>
                                    <button
                                        className={(actionToShow === item.label) ? "text-uppercase nav-link active" : "text-uppercase nav-link text-primary"}
                                        aria-current={(actionToShow === item.label) && "true"}
                                        onClick={e=>{
                                            e.preventDefault();
                                            setActionToShow(item.label);
                                        }}>
                                        {item.text}
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="card-body" >
                    <div className="d-print-none">
                        { actionHeaderContent }
                    </div>
                    {(filtersToPrint.length > 0) && (
                        <div className="d-none d-print-block">
                            <h5>Filtros</h5>
                            <div className="row">{filtersToPrint}</div>
                        </div>
                    )}

                </div>
            </div>
            <PropertiesTable filters={propertiesFilters} fieldsStatus={enabledFields} />
        </main>
    );
};

export default Main;