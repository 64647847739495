import Select from "react-select";

const FilterSelect = props => {
    let placeholderText = "Cargando filtro...";
    let isDisabled = true;

    let filterOptions = [];

    if (props.status === 'error') {
        placeholderText = "Error al cargar el filtro";
    }

    if (props.status === 'success') {
        placeholderText = "Todas las opciones";
        isDisabled = false;

        if (props.filters && Object.keys(props.filters).length > 0 && props.filters.constructor === Object) {
            for (let filterValue in props.filters) {
                if (typeof filterValue === 'string' && typeof props.filters[filterValue] === 'string') {
                    filterOptions.push({label: props.filters[filterValue], value: filterValue, key: filterValue});
                }
            }
        }
    }



    return (
        <div className="filterSelect">
            <label htmlFor={`filter_${props.name}`}>{props.label}</label>
            <Select
                id={`filter_${props.name}`}
                aria-label={`${props.name} filter for properties`}
                options={filterOptions}
                value={props.defaultValue || ''}
                placeholder={placeholderText}
                isMulti={props.isMulti}
                isLoading={props.status === 'loading'}
                isDisabled={isDisabled}
                isSearchable={props.isSearchable}
                isClearable={props.isClearable}
                onChange={props.onFilterChange}
            />
        </div>
    );
};

export default FilterSelect;