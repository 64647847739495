import {useEffect, useState} from 'react';

import Pagination from './Pagination';

import { getProperties } from '../../api/requests';
import { createTableHeader, createTableContent } from '../../api/functions';

// PropertiesTable component
const PropertiesTable = ({filters, fieldsStatus}) => {
    const propertiesPerPage = 25;

    const [properties, setProperties] = useState([]);

    // content status
    const [contentStatus, setContentStatus] = useState('loadign');

    // Total properties -> required for pagination
    const [totalProperties, setTotalProperties] = useState(0);

    // Current page -> required for pagination
    const [currentPage, setCurrentPage] = useState(1);

    // Table headers
    const [tableHeaders, setTableHeaders] = useState([]);

    // Tabke content
    const [tableContent, setTableContent] = useState([]);

    // Get properties
    useEffect(() => {
        setContentStatus('loading');

        getProperties(filters, currentPage, propertiesPerPage).then(response => {
            if (response) {
                setContentStatus('success');
                setProperties(response.content);
                setTotalProperties(response.total);
            } else {
                console.warn('Get proeprties: Error from response');
                setContentStatus('error');
                setProperties([]);
                setTotalProperties(0);
            }
        })
        .catch(error => {
            console.warn('Get proeprties: Error from AJAX');
            setContentStatus('error');
            setProperties([]);
            setTotalProperties(0);
        });
    }, [filters, currentPage]);

    useEffect(() => {setCurrentPage(1)}, [filters])

    // Recreate table content
    useEffect(() => {
        const colSpan = tableHeaders.length > 1 ? tableHeaders.length : null;
        const newTableContent = createTableContent(properties, contentStatus, fieldsStatus, colSpan);

        if (newTableContent) setTableContent(newTableContent);
    }, [properties, contentStatus, fieldsStatus, tableHeaders]);

    // Recreate table headings
    useEffect(() => {
        const newTableHeader = createTableHeader(fieldsStatus);
        if (newTableHeader && newTableHeader.length > 0) setTableHeaders(newTableHeader);
    }, [fieldsStatus]);


    return (
        <div className="pb-4">
            <div className="row justify-content-between mb-3">
                <div className="col-12 col-md">
                    <h4>Lista de propiedades</h4>
                </div>
                <div className="col-12 col-md">
                    <Pagination
                        currentPage={currentPage}
                        totalProperties={totalProperties}
                        perPage={propertiesPerPage}
                        onPaginationChange={setCurrentPage}
                        status={contentStatus}
                    />
                </div>
            </div>

            <table className="properties_table table table-striped table-hover">
                <thead>
                    <tr>{tableHeaders}</tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
        </div>
    );
};

export default PropertiesTable;