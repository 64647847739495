import {useState, useEffect} from 'react';
import { apiFields } from '../../api/apiFields';



const FieldsSelect = ({fields, onChangeFields}) => {
    const [enabledFields, setEnabledFields] = useState(fields);
    const [canUpdateFields, setCanUpdateFields] = useState(false);

    useEffect(() => {
        setCanUpdateFields(JSON.stringify(fields) === JSON.stringify(enabledFields));
    }, [fields, enabledFields]);

    const changeFieldStatus = field => {
        const currentEnabledFields = {...enabledFields};
        currentEnabledFields[field] = !currentEnabledFields[field];
        setEnabledFields(currentEnabledFields);
    }

    const fieldsToSelect = [];
    for (let field in apiFields) {
        fieldsToSelect.push((
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-1 px-2 py-1" key={field}>
                <div className="form-check">
                    <input
                        name={field}
                        className="form-check-input"
                        type="checkbox"
                        id={`${field}Checkbox`}
                        checked={enabledFields[field]}
                        onChange={e => changeFieldStatus(e.target.name)}/>
                    <label className="form-check-label" htmlFor={`${field}Checkbox`}>
                        {apiFields[field].label}
                    </label>
                </div>
            </div>
        ));
    }

    const updateFields = () => {
        //Save new filters in localstorage
        localStorage.setItem('NMP_appliedFields', JSON.stringify(enabledFields));
        //Set new filters
        onChangeFields(enabledFields);
    }


    return (
        <>
            <h5 className="card-title">Filtrar propiedades</h5>
            <div className="row py-2">
                {fieldsToSelect}
            </div>
            <div className="d-flex justify-content-center py-2 mt-3">
                <button
                    className="btn btn-primary text-uppercase px-5"
                    disabled={canUpdateFields}
                    onClick={updateFields}>
                    Actualizar tabla
                </button>
            </div>
        </>
    );
};

export default FieldsSelect;