import Header from './sections/Header';
import Footer from './sections/Footer';
import Main from './sections/Main/Main';


function App() {
  return (
    <article className="App">
      <Header />
      <Main />
      <Footer />
    </article>
  );
}

export default App;
