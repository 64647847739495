import {useState, useEffect} from 'react';

import FilterSelect from '../../components/FilterSelect';

const Filters = props => {
    let filtersTimeOut;

    const {propertyType, transactionType, city} = props.settedFilters;

    const [propertyTypeFilter, setPropertyTypeFilter] = useState(propertyType);
    const [transactionTypeFilter, setTransactionTypeFilter] = useState(transactionType);
    const [cityFilter, setCityFilter] = useState(city);

    // Update all filters every time any filter change
    useEffect(() => {
        if (filtersTimeOut) clearTimeout(filtersTimeOut);

        filtersTimeOut = setTimeout(() => {
            const currentFilters = {
                city: cityFilter || '',
                propertyType: propertyTypeFilter || '',
                transactionType: transactionTypeFilter || ''
            }

            //Save new filters in localstorage
            localStorage.setItem('NMP_appliedFilters', JSON.stringify(currentFilters));

            props.onFiltersChange(currentFilters);
        }, 300);

    }, [propertyTypeFilter,
        transactionTypeFilter,
        cityFilter]);

    useEffect(() => {
        setCityFilter(city);
    }, [city]);

    useEffect(() => {
        setPropertyTypeFilter(propertyType);
    }, [propertyType]);

    useEffect(() => {
        setTransactionTypeFilter(transactionType);
    }, [transactionType]);

    useEffect(() => {
        return () => {
            if (filtersTimeOut) clearTimeout(filtersTimeOut);
        }
    }, []);

    return (
        <>
            <h5 className="card-title">Filtrar propiedades</h5>
            <div className="row py-2">
                <div className="col-md-4 col-12 my-1 p-3">
                    <FilterSelect
                        filters={props.filters.cities}
                        status={props.status}
                        name={'cities'}
                        label={'Ciudad'}
                        defaultValue={cityFilter}
                        onFilterChange={setCityFilter}
                        isMulti={true}
                        isClearable={true}
                    />
                </div>

                <div className="col-md-4 col-12 my-1 p-3">
                    <FilterSelect
                        filters={props.filters.properties_type}
                        status={props.status}
                        name={'properties_type'}
                        label={'Tipo de propiedad'}
                        defaultValue={propertyTypeFilter}
                        onFilterChange={setPropertyTypeFilter}
                        isMulti={true}
                        isClearable={true}
                    />
                </div>

                <div className="col-md-4 col-12 my-1 p-3">
                    <FilterSelect
                        filters={props.filters.transactions_type}
                        status={props.status}
                        name={'transactions_type'}
                        label={'Tipo de operación'}
                        defaultValue={transactionTypeFilter}
                        onFilterChange={setTransactionTypeFilter}
                        isMulti={false}
                        isClearable={true}
                    />
                </div>

            </div>
        </>
    );
};

export default Filters;