export const apiFields = {
    name: {
        label: "Nombre",
        fields: "title"
    },
    ref: {
        label: "Ref.",
        fields: "id"
    },
    prop_type: {
        label: "Propiedad",
        fields: "property_type.name"
    },
    transaction: {
        label: "Operación",
        fields: "transaction_type.name"
    },
    dir: {
        label: "Dirección",
        fields: ['address', 'address_number'],
        separator: ' '
    },
    neighborhood: {
        label: "Barrio",
        fields: "neighborhood"
    },
    location: {
        label: "Ubicación",
        fields: ['city.name', 'state.name', 'country.name'],
        separator: ', '
    },
    rooms: {
        label: "Amb",
        fields: "rooms"
    },
    bedrooms: {
        label: "Dorm.",
        fields: "bedrooms"
    },
    bathrooms: {
        label: "Baños",
        fields: "bathrooms"
    },
    garages: {
        label: "Gge.",
        fields: "garages"
    },
    area: {
        label: "Sup. Total",
        fields: "m2",
        suffix: "m2"
    },
    area_covered: {
        label: "Sup. Cub.",
        fields: "m2_covered",
        suffix: "m2"
    },
    area_ground: {
        label: "Hectareas",
        fields: "hectares",
        suffix: "ha"
    },
    expenses: {
        label: "Expensas",
        fields: ['expenses_currency.name', 'expenses'],
        separator: ' '
    },
    price: {
        label: "Precio",
        fields: ['currency.name', 'price'],
        separator: ' '
    }
};
